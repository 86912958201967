import React, {FC} from 'react';
import SvgSelector from '../SvgSelector/SvgSelector';
import styles from './ApplicationСard.module.scss'
import {AppButton} from '../AppButton/AppButton';

interface CardType {
    title: string
    text: string
    textApp?: string
    iconApp?: string
    linkApp?: string[]
    icon?: string
    background: string
    width?: string
    svgColor?: string
}

export const ApplicationCard: FC<CardType> = ({title, text, linkApp, icon, background, width, svgColor}) => {

    let linkGoogle
    let linkApple
    if (linkApp) {
        linkGoogle = linkApp[0]
        linkApple = linkApp[1]
    }



    return (
        <div className={styles.card} style={{background}}>
            <div className={styles.card_header}>
                <h3 style={{maxWidth: width}} className={styles.card_header__title}>{title}</h3>
                <SvgSelector id={icon} svgColor={svgColor}/>
            </div>
            <div className={styles.card_main}>
                <p className={styles.card_main__text}>{text}</p>
            </div>
            <div className={styles.card_footer}>
                <div className={styles.card_footer__buttons}>
                    <a href={linkGoogle} className={styles.card_footer__button} target={'_blank'}>
                        <SvgSelector id={'googleplay-icon'}/>
                    </a>
                    <a href={linkApple}
                       className={styles.card_footer__button} target={'_blank'}>
                        <SvgSelector id={'appstore-icon'}/>
                    </a>
                </div>
            </div>
        </div>
    )
}