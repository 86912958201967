import React, {FC, useEffect, useState} from 'react';
import SvgSelector from '../SvgSelector/SvgSelector';



interface AccordionType {
    title: string
    text?: string
    linkText?: string
    number: number
    img?: string
    img2?: string
    popupIsOpenHandler: (img: string) => void
    nameVideoLink?: string
}

export const Accordion: FC<AccordionType> = ({title, text, linkText, nameVideoLink, number, img, img2, popupIsOpenHandler}) => {
    const [isViewFloorPlan, setIsViewFloorPlan] = useState(0);


    const viewFloorPlanHandler = (value: number) => {
        console.log('test viewFloorPlanHandler')
        if (value !== 0 && value !== isViewFloorPlan) {
            setIsViewFloorPlan(value)
        } else {
            setIsViewFloorPlan(0)
        }
    }

    return (
        <div className={'plan-info'}>
            <div onClick={() => viewFloorPlanHandler(number)} className={'plan-info-static'}>
                <div className={'plan-info-static__title'}>
                    <h3>{title}</h3>
                </div>
                <div className={isViewFloorPlan === number ? 'plan-info__button active' : 'plan-info__button'}>
                    <SvgSelector id={'arrow-accordion-icon'}/>
                </div>
            </div>
            <div className={'plan-info-dynamism'}>
                <div
                    className={isViewFloorPlan === number ? 'plan-info-dynamism__floor active' : 'plan-info-dynamism__floor'}>
                    {nameVideoLink &&
                        <div  className={'video-wrap'}>
                            <iframe
                                src={nameVideoLink}
                                frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                className={'video'}
                                title="telegram-cloud-document-2-5451643104534736188"></iframe>
                        </div>
                    }
                    <div className={'plan-info-text'}>
                        <h3>{text} <a href="https://www.antalyakart.com.tr" target={'_blank'}>{linkText}</a></h3>
                    </div>
                    {img! && <p>терминал</p>}
                    {img! && <div
                        className={isViewFloorPlan === number ? 'plan-info-dynamism__image active' : 'plan-info-dynamism__image'}>
                        <img onClick={() => popupIsOpenHandler(img!)}
                             src={img} alt={'floor-three'}/>
                    </div>}
                    {img2! && <p>вывеска у магазина</p>}
                    {img2! && <div
                        className={isViewFloorPlan === number ? 'plan-info-dynamism__image active' : 'plan-info-dynamism__image'}>
                        <img onClick={() => popupIsOpenHandler(img2!)}
                             src={img2!} alt={'floor-three'}/>
                    </div>}
                </div>
            </div>
        </div>
    )
}