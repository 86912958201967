import React, {useCallback, useEffect, useRef, useState} from 'react'

import {useNavigate} from 'react-router-dom'
import SvgSelector from '../../components/SvgSelector/SvgSelector'
import {Loading} from '../../components/Loading/Loading'
import {useAppSelector} from '../../store/store'
import './Home.scss'
import {AppTitle} from '../../components/AppTitle/AppTitle'
import AppPopup from '../../components/AppPopup/AppPopup'
import {blockScrolling} from '../../utils/blockScrolling'
import local from '../../assets/img/information/local.png'
import map from '../../assets/img/information/map.png'
import {Logo} from '../../components/logo/Logo'
import {Card} from '../../components/Card/Card'
import {ApplicationCard} from '../../components/ApplicationСard/ApplicationСard'

import styles from './Home.module.scss'

import antalyaAirportIcon from '../../assets/img/icons/antalya-airport.png'
import hospitalIcon from '../../assets/img/icons/hospital.png'
import shoppingCenterIcon from '../../assets/img/icons/shopping-centre.png'
import {AppButton} from '../../components/AppButton/AppButton'
import {Accordion} from '../../components/Accordion/Accordion'

import terminal from '../../assets/img/information/terminal.jpg'
import terminalInfo from '../../assets/img/information/terminalInfo.jpg'
import Menu from '../../components/AppMenu/Menu'


export const Home = () => {
    const {isInitializeApp} = useAppSelector(state => state.app)
    const homeRef = useRef<any | null>(null)
    const contactsRef = useRef<any | null>(null)
    const transportInformationRef = useRef<any | null>(null)
    const appRef = useRef<any | null>(null)
    const transportCardRef = useRef<any | null>(null)
    const locationsInformationRef = useRef<any | null>(null)
    const usefulLocationsRef = useRef<any | null>(null)
    const touristInformationRef = useRef<any | null>(null)
    const informationBureauRef = useRef<any | null>(null)
    const placementDepartmentRef = useRef<any | null>(null)
    const googleMapRef = useRef<any | null>(null)
    const airportAntalyaRef = useRef<any | null>(null)
    const pharmacyRef = useRef<any | null>(null)
    const taxiRef = useRef<any | null>(null)
    const hospitalRef = useRef<any | null>(null)
    const connectionRef = useRef<any | null>(null)
    const shoppingCenterRef = useRef<any | null>(null)
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const iconPng = antalyaAirportIcon


    const menuIsOpenHandler = () => {
        setMenuIsOpen(!menuIsOpen)

    }
    useEffect(() => {
        if (menuIsOpen) {
            document.body.style.overflow = 'hidden'
        }
        return () => {
            document.body.style.overflow = 'scroll'
        }
    }, [menuIsOpen])


    const mapMain = 'https://firebasestorage.googleapis.com/v0/b/tourist-kaleici.appspot.com/o/image%2Fmaps%2FmapMain.png?alt=media&token=aa9bf3bd-9dae-43c1-b56d-7571ff77aa48'
    const text1 = 'Транспортную карту  Antalya Kart можно приобрести в терминале или в магазине со специальной вывеской. Посмотреть ближайшую точку продажи вы можете'


    const [popupIsOpen, setPopupIsOpen] = useState(false)
    const [activeImgPopup, setActiveImgPopup] = useState('')


    const popupIsOpenHandler = (value: string) => {
        console.log(value)
        setActiveImgPopup(value)
        setPopupIsOpen(!popupIsOpen)

    }

    useEffect(() => {
        if (popupIsOpen) {
            blockScrolling(true)
        } else {
            blockScrolling(false)
        }
        return () => (blockScrolling(false))
    }, [popupIsOpen])

    const handleClick = (value: any) => {
        console.log(value)
        setMenuIsOpen(!menuIsOpen)
        switch (value) {
            case 'homeRef':
                window.scrollTo({behavior: 'smooth', top: homeRef.current.offsetTop})
                break
            case 'contactsRef':
                window.scrollTo({behavior: 'smooth', top: contactsRef.current.offsetTop - 60})
                break
            case 'transportInformationRef':
                window.scrollTo({behavior: 'smooth', top: transportInformationRef.current.offsetTop - 60})
                break
            case 'appRef':
                window.scrollTo({behavior: 'smooth', top: appRef.current.offsetTop - 60})
                break
            case 'transportCardRef':
                window.scrollTo({behavior: 'smooth', top: transportCardRef.current.offsetTop + 1210})
                break
            case 'usefulLocationsRef':
                window.scrollTo({behavior: 'smooth', top: usefulLocationsRef.current.offsetTop - 60})
                break
            case 'touristInformationRef':
                window.scrollTo({behavior: 'smooth', top: touristInformationRef.current.offsetTop + 200})
                break
            case 'informationBureauRef':
                window.scrollTo({behavior: 'smooth', top: informationBureauRef.current.offsetTop + 200})
                break
            case 'placementDepartmentRef':
                window.scrollTo({behavior: 'smooth', top: placementDepartmentRef.current.offsetTop + 200})
                break
            case 'googleMapRef':
                window.scrollTo({behavior: 'smooth', top: googleMapRef.current.offsetTop - 60})
                break
            case 'airportAntalyaRef':
                window.scrollTo({behavior: 'smooth', top: airportAntalyaRef.current.offsetTop - 60})
                break
            case 'taxiRef':
                window.scrollTo({behavior: 'smooth', top: taxiRef.current.offsetTop - 60})
                break
            case 'pharmacyRef':
                window.scrollTo({behavior: 'smooth', top: pharmacyRef.current.offsetTop - 60})
                break
            case 'hospitalRef':
                window.scrollTo({behavior: 'smooth', top: hospitalRef.current.offsetTop - 60})
                break
            case 'shoppingCenterRef':
                window.scrollTo({behavior: 'smooth', top: shoppingCenterRef.current.offsetTop - 60})
                break
            case 'connectionRef':
                window.scrollTo({behavior: 'smooth', top: connectionRef.current.offsetTop - 60})
                break
            default:
                console.log('error')
        }
    }


    const [userScroll, setUserScroll] = useState(true)

    const [scroll, setScroll] = useState(window.scrollY)
    const handleNavigation = useCallback((e: any) => {
            const window = e.currentTarget
            if (scroll > window.scrollY) {
                setUserScroll(true)
                // console.log("scrolling up");
            } else if (scroll < window.scrollY) {
                setUserScroll(false)
                // console.log("scrolling down");
            }
            setScroll(window.scrollY)
        },
        [scroll]
    )

    useEffect(() => {
        setScroll(window.scrollY)
        window.addEventListener('scroll', handleNavigation)

        return () => {
            window.removeEventListener('scroll', handleNavigation)
        }
    }, [handleNavigation])

    if (!isInitializeApp) {
        return (
            <Loading/>
        )
    }

    return (
        <div ref={homeRef} className={'home'}>
            {!menuIsOpen && <div className={userScroll ? 'header active home__header' : 'header'}>
                <div className={'home__container'}>
                    <div className={'menu__wrap'}>
                        <div className={'logo'}>
                            <Logo/>
                        </div>
                        <div className={'menu'}>
                            <div className={'menu-burger'} onClick={menuIsOpenHandler}>
                                <span className={'menu-burger__item'}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div className={'main home__main'}>
                <div className={'main-first-screen'}>
                    <div className={'home__container'}>
                        <h1 className={'main-first-screen__title'}>Полезная информация для делегатов</h1>
                    </div>
                </div>
                <div ref={contactsRef} className={'main-contacts'}>
                    <div className={'home__container'}>
                        <AppTitle title={'Конактная информация'}>
                            <SvgSelector id={'contacts-icon'}/>
                        </AppTitle>
                        <div className={'main-contacts__header'}>
                            <div ref={hospitalRef}>
                                <Card title={'первой помощи'}
                                      icon={'hospital'}
                                      iconMessenger={'telegram-icon'}
                                      text={''}
                                      phone={'90 534 760 43 70'}
                                      messenger={'Телеграмм'}
                                      linkMessenger={' '}
                                      phone2={'90 551 092 98 55'}
                                      linkMessenger2={' '}
                                      hospital={true}
                                />

                            </div>
                            <div ref={placementDepartmentRef}>
                                <Card title={'Отдел размещения'}
                                      icon={'home-icon'}
                                      iconMessenger={'telegram-icon'} text={''}
                                      phone={'90 534 760 43 70'}
                                      messenger={'Телеграмм'}
                                      linkMessenger={' '}
                                      phone2={'90 551 092 98 55'}
                                      linkMessenger2={' '}
                                />

                            </div>
                            <div ref={informationBureauRef}>
                                <Card title={'Бюро информации'}
                                      phone={'90 552 658 55 91'}
                                      phone2={'90 534 684 81 92'}
                                      icon={'info-icon'}
                                      messenger={'Телеграмм'}
                                      linkMessenger={''}
                                    // linkMessenger={'https://t.me/Avva0562A'}
                                      iconMessenger={'telegram-icon'}
                                      text={''}
                                    // add={true}
                                />

                            </div>
                            <div ref={touristInformationRef}>
                                <Card title={'Справочная для туристов'}
                                      phone={'157'}
                                      icon={'tourist-icon'}
                                      messenger={'Телеграмм'}
                                      linkMessenger={'https://t.me/Safario_88'}
                                      iconMessenger={'telegram-icon'}
                                      text={'— звонок возможен только с городского номера'}
                                      plus={''}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div ref={locationsInformationRef} className={'main-locations-information'}>
                    <div className={'home__container'}>
                        <AppTitle title={'Информация о расположении комплекса'}>
                            <SvgSelector id={'plan-section-icon'}/>
                        </AppTitle>
                        <div className={'main-transport-information__map map'}>
                            <div className={'map__text'} onClick={(e) => popupIsOpenHandler(local)}>
                                <div className={'map__text-position'}>
                                    {/*<h3>Схема трамвая</h3>*/}
                                    <SvgSelector id={'search-icon'}/>
                                </div>
                            </div>
                            <div className={'map__image'}>
                                <img src={local} alt="map"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={transportInformationRef} className={'main-transport-information'}>
                    <div className={'home__container'}>
                        <AppTitle title={'Информация о транспорте'}>
                            <SvgSelector id={'plan-section-icon'}/>
                        </AppTitle>
                        <div className={'main-transport-information__cards cards'}>
                            <div className={'cards__item'}>
                                <div>
                                    <h3>Стоимость проезда</h3>
                                    <div>20 TL</div>
                                </div>
                                <div>
                                    <h3>Стоимость пересадки</h3>
                                    <div>7.5 TL</div>
                                </div>
                            </div>
                            <div className={'cards__item'}>
                                <div>
                                    <a href="https://goo.gl/maps/6a4QXk1qw9VTT8b26" target={'_blank'}>
                                        <div>
                                            <SvgSelector id={'map-icon'}/>
                                        </div>
                                        <h3>Место проведения</h3>
                                    </a>
                                </div>
                                <div>
                                    <a href="https://goo.gl/maps/6a4QXk1qw9VTT8b26" target={'_blank'}>
                                        <h3 className={'cards__item_title'}>ANFAŞ Antalya Expo Center</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'main-transport-information__map map'}>
                            <div className={'map__text'} onClick={(e) => popupIsOpenHandler(mapMain)}>
                                <div className={'map__text-position'}>
                                    <h3>Схема трамвая</h3>
                                    <SvgSelector id={'search-icon'}/>
                                </div>
                            </div>
                            <div className={'map__image'}>
                                <img src={map} alt="map"/>
                            </div>
                        </div>
                        <div className={'main-transport-information__info main-floor-plan'}>
                            <div ref={transportCardRef} className={'cart-main-transport-information__info'}>
                                <h3>Транспортная карта</h3>
                                <SvgSelector id={'antalya-card-icon'}/>
                            </div>
                            <div className={'plan-info-wrap'}>
                                <Accordion title={'Где купить транспортную карту Antalya Kart?'} text={text1}
                                           linkText={'в приложении'}
                                           number={4} img={terminal}
                                           img2={terminalInfo}
                                           popupIsOpenHandler={(value) => popupIsOpenHandler(value)}/>
                                <Accordion title={'Как купить карту в терминале?'} number={1}
                                           text={'На данный момент информации нет'}
                                           img={''}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler('')}/>
                                <Accordion title={'Как пополнить карту через терминал?'} number={3} img={''}
                                           text={'На данный момент информации нет'}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler('')}/>
                                <Accordion title={'Как оплатить поездку картой?'} number={3} img={''}
                                           text={'На данный момент информации нет'}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler('')}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={appRef} className={'cart app'}>
                    <div className={'home__container'}>
                        <div className={'cart-header'}>
                            <h3>Приложение antalya kart</h3>
                            <SvgSelector id={'antalya-card-icon'}/>
                        </div>
                        <ApplicationCard title={'Скачать Antalya Kart'} text={'Скачайте приложение для android или ios'}
                                         icon={'download-icon'} background={'#8878D2'}
                                         svgColor={'#CFC6F9'}
                                         linkApp={['https://play.google.com/store/apps/details?id=kentkart.mobile.antalyakart&hl=ru&gl=US', 'https://apps.apple.com/us/app/antalyakart/id1076290540']}/>
                        <div className={'main-floor-plan'}>
                            <div className={'plan-info-wrap plan-info-wrap-card'}>
                                <Accordion title={'Как найти точку пополнения карты'}
                                           nameVideoLink={'https://player.vimeo.com/video/831239427?h=c5aa3334eb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
                                           number={4} img={''}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(terminal)}/>
                                <Accordion title={'Как найти ближайшую ко мне остановку'}
                                           number={4} img={''}
                                           nameVideoLink={'https://player.vimeo.com/video/831242684?h=1ac606880a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(terminal)}/>
                                <Accordion title={'Как посмотреть какие автобусы идут через мою остановку'}
                                           nameVideoLink={'https://player.vimeo.com/video/831237742?h=313387263d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
                                           number={4} img={''}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(terminal)}/>
                                <Accordion title={'Как найти нужный автобус'}
                                           nameVideoLink={'https://player.vimeo.com/video/831238506?h=29d17fb51d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
                                           number={4} img={''}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(terminal)}/>
                                <Accordion title={'Как посмотреть расписание автобуса'}
                                           nameVideoLink={'https://player.vimeo.com/video/831243041?h=3de59f5daf&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
                                           number={4} img={''}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(terminal)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={googleMapRef} className={'cart google'}>
                    <div className={'home__container'}>
                        <div className={'cart-header'}>
                            <h3>Гугл карты</h3>
                            <SvgSelector id={'map-google-icon'}/>
                        </div>
                        <ApplicationCard title={'Скачать Гугл карты'} text={'Скачайте приложение для android или ios'}
                                         icon={'download-icon'} background={'#00529C'}
                                         svgColor={'#9CBFED'}
                                         linkApp={['https://play.google.com/store/apps/details?id=com.google.android.apps.maps&hl=ru&gl=US', 'https://apps.apple.com/ru/app/google-%D0%BA%D0%B0%D1%80%D1%82%D1%8B-%D1%82%D1%80%D0%B0%D0%BD%D1%81%D0%BF%D0%BE%D1%80%D1%82-%D0%B8-%D0%B5%D0%B4%D0%B0/id585027354']}/>
                        <div className={'main-floor-plan'}>
                            <div className={'plan-info-wrap plan-info-wrap-card'}>
                                <Accordion title={'Как построить маршрут в гугл картах'}
                                           number={4} img={''}
                                           nameVideoLink={'https://player.vimeo.com/video/831236006?h=f9ef440022&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(terminal)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={airportAntalyaRef} className={'cart airport'}>
                    <div className={'home__container'}>
                        <div className={'cart-header'}>
                            <h3>Аэропорт анталии</h3>
                            {<img src={iconPng} alt=""/> || <SvgSelector id={'taxi-icon'}/>}
                        </div>
                        <div className={styles.airport}>
                            <div className={styles.airport__item}>
                                <p className={styles.airport__text}>Сайт аэропорта, где вы сможете посмотреть расписание
                                    всех рейсов</p>
                                <div className={styles.airport__button}>
                                    <a href="https://www.antalya-airport.aero/homepage" target={'_blank'}>
                                        <AppButton mode={'white'}>Открыть сайт</AppButton>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.airport__item}>
                                <p className={styles.airport__text}>Интерактивные схемы терминалов аэропорта</p>
                                <div className={styles.airport__button} style={{marginBottom: 0}}>
                                    <a href="https://www.antalya-airport.aero/katplanlari/index.html" target={'_blank'}>
                                        <AppButton mode={'white'}>Открыть схемы терминаров</AppButton>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={taxiRef} className={'cart taxi'}>
                    <div className={'home__container'}>
                        <div className={'cart-header'}>
                            <h3>Такси</h3>
                            <SvgSelector id={'taxi-icon'}/>
                        </div>
                        <div className={'main-floor-plan'}>
                            <div className={'plan-info-wrap plan-info-wrap-card'}>
                                <Accordion title={'Способы вызова такси'}
                                           text={'На данный момент информации нет'}
                                           number={4}
                                           img={''}
                                           popupIsOpenHandler={(e) => popupIsOpenHandler(terminal)}/>
                            </div>
                        </div>
                        <div className={styles.cards}>
                            <div className={styles.cards__item}>
                                <div>
                                    <h3>Плата за посадку</h3>
                                    <div>25 TL</div>
                                </div>
                                <div>
                                    <h3>Плата за 1 км</h3>
                                    <div>30 TL</div>
                                </div>
                                <div>
                                    <h3>Минимальная стоимость поездки</h3>
                                    <div>100 TL</div>
                                </div>
                            </div>
                            <div className={styles.cards__item}>
                                <div>
                                    <SvgSelector id={'taxi-counter-icon'}/>
                                </div>
                                <div>
                                    <h3 className={styles.cards__item_title}>При посадке в такси включается счетчик.
                                        Стоимость поездки отображается на зеркале заднего вида водителя</h3>
                                </div>
                            </div>
                        </div>

                        <ApplicationCard title={'Скачать приложение Uber'}
                                         text={'Скачайте приложение для android или ios'}
                                         icon={'download-icon'} background={'#C9920B'} width={'250px'}
                                         svgColor={'#EBC873'}
                                         linkApp={['https://play.google.com/store/apps/details?id=com.ubercab&hl=ru&gl=US', 'https://apps.apple.com/ru/app/uber-%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7-%D0%BF%D0%BE%D0%B5%D0%B7%D0%B4%D0%BE%D0%BA/id368677368']}
                        />
                        <div className={'taxi__applicationCard_margin-top'}>
                            <div className={'main-floor-plan'}>
                                <div className={'plan-info-wrap plan-info-wrap-card'}>
                                    <Accordion title={'Заказ такси через приложение  UBER'}
                                               number={4} img={''}
                                               nameVideoLink={'https://player.vimeo.com/video/831222607?h=8d29f8570d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
                                               popupIsOpenHandler={(e) => popupIsOpenHandler(terminal)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={usefulLocationsRef} className={'home__container'}>
                    <div className={styles.title}>
                        <AppTitle title={'Полезные локации'}>
                            <SvgSelector id={'contacts-icon'}/>
                        </AppTitle>
                    </div>
                </div>
                <div ref={pharmacyRef} className={'cart pharmacy'}>
                    <div className={'home__container'}>
                        <div className={'cart-header'}>
                            <h3>Аптеки</h3>
                            <SvgSelector id={'pharmacy-icon'}/>
                        </div>
                        <div className={styles.pharmacy}>
                            <div className={styles.pharmacy__item}>
                                <p className={styles.pharmacy__text}>
                                    Самый простой способ посмотреть ближайшие аптеки — написать в поисковой строке гугл
                                    карт слово «ECZANE». Карты покажут вам ближайшие к вам аптеки</p>
                                <div className={styles.pharmacy__button}>
                                    <a href="https://www.google.com/maps/search/en+yak%C4%B1n+eczaneyi+bulun/@36.8963292,30.7002028,15.51z?entry=ttu"
                                       target={'_blank'}>
                                        <AppButton mode={'white'}>Посмотреть ближайшие аптеки</AppButton>
                                    </a>
                                </div>
                                {/*<div className={styles.tablet_decor}>*/}
                                {/*    <img src={tabletDecor} alt="none"/>*/}
                                {/*</div>*/}
                            </div>
                            <div className={styles.pharmacy__item}>
                                <div className={styles.pharmacy__icon}>
                                    <SvgSelector id={'alert-icon'}/>
                                </div>
                                <p className={styles.pharmacy__text}>
                                    Большинство аптек города работают до 18 – 19 часов.
                                    В воскресенье все аптеки закрыты, за исключением дежурных. Список дежурных аптек
                                    можно посмотреть на специальном сайте
                                </p>
                                <div className={styles.pharmacy__button} style={{marginBottom: 0}}>
                                    <a href="https://www.antalyaeo.org.tr/tr/nobetci-eczaneler" target={'_blank'}>
                                        <AppButton mode={'white'}>Посмотреть дежурные аптеки</AppButton>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={hospitalRef} className={'cart hospital'}>
                    <div className={'home__container'}>
                        <div className={'cart-header'}>
                            <h3>Больницы</h3>
                            <img src={hospitalIcon} alt=""/>
                        </div>
                        <div className={styles.hospital}>
                            <div className={styles.hospital__item}>
                                <p className={styles.hospital__text}>
                                    В Анталии есть несколько крупных частных больниц с русскоговорящим персоналом. В них
                                    можно сделать любые анализы, посетить врача, пройти диагностику. Ниже телефоны
                                    русскоговорящих администраторов, у которых вы можете записаться на прием и узнать
                                    цены. Самый лучший способ написать сообщение в ватсапп.
                                </p>
                            </div>
                            <div className={styles.hospital__info}>
                                <p className={styles.hospital__name}>Akdeniz Sifa</p>
                                <a href={`tel: +90 507 817 35 08`} className={styles.hospital__phone} target={'_blank'}>
                                    +90 507 817 35 08
                                </a>
                                <div className={styles.hospital__buttons}>
                                    <a href={'https://goo.gl/maps/7CQhTU4cNPXsEAZj8'}
                                       className={styles.hospital__button} target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            На карте
                                        </AppButton>
                                    </a>
                                    <a href={`https://wa.me/905078173508`} className={styles.hospital__button}
                                       target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            Ватсап
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.hospital__info}>
                                <p className={styles.hospital__name}>Olympos Hospital</p>
                                <a href={`tel: +90 542 278 92 99`} className={styles.hospital__phone} target={'_blank'}>
                                    +90 542 278 92 99
                                </a>
                                <div className={styles.hospital__buttons}>
                                    <a href={'https://goo.gl/maps/dZNdtXokgbTUSDBU6'}
                                       className={styles.hospital__button} target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            На карте
                                        </AppButton>
                                    </a>
                                    <a href={`https://wa.me/905422789299`} className={styles.hospital__button}
                                       target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            Ватсап
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.hospital__info}>
                                <p className={styles.hospital__name}>Meydan Hastanesi</p>
                                <a href={`tel: +90 538 396 61 06`} className={styles.hospital__phone} target={'_blank'}>
                                    +90 538 396 61 06
                                </a>
                                <div className={styles.hospital__buttons}>
                                    <a href={'https://goo.gl/maps/aaH3RnRLpNKQ1Wft6'}
                                       className={styles.hospital__button} target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            На карте
                                        </AppButton>
                                    </a>
                                    <a href={`https://wa.me/905383966106`} className={styles.hospital__button}
                                       target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            Ватсап
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={shoppingCenterRef} className={'cart shoppingCenterIcon'}>
                    <div className={'home__container'}>
                        <div className={'cart-header'}>
                            <h3>Торговые центры</h3>
                            <img src={shoppingCenterIcon} alt=""/>
                        </div>
                        <div className={styles.shopping_center}>
                            <div className={styles.shopping_center__item}>
                                <p className={styles.shopping_center__text}>
                                    Ниже указаны самые крупные торговые центры города. В некоторых случаях, возможно
                                    будут приниматься к оплате российские карты Union Pay. В некоторых центрах еть
                                    бесплатный wi-fi.
                                </p>
                            </div>
                            <div className={styles.shopping_center__info}>
                                <p className={styles.shopping_center__name}>Mark Antalya</p>
                                <div className={styles.shopping_center__buttons}>
                                    <a href={'https://goo.gl/maps/UFXsjdTrz6hfenFz8'}
                                       className={styles.shopping_center__button} target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            Посмотреть на карте
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.shopping_center__info}>
                                <p className={styles.shopping_center__name}>5m migros</p>
                                <div className={styles.shopping_center__buttons}>
                                    <a href={'https://goo.gl/maps/MHFeyLbgvxu3FW3JA'}
                                       className={styles.shopping_center__button} target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            Посмотреть на карте
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.shopping_center__info}>
                                <p className={styles.shopping_center__name}>Ozdilek park</p>
                                <div className={styles.shopping_center__buttons}>
                                    <a href={'https://goo.gl/maps/D6ZZLqBxH6z5Rn8u7'}
                                       className={styles.shopping_center__button} target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            Посмотреть на карте
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.shopping_center__info}>
                                <p className={styles.shopping_center__name}>Erasta</p>
                                <div className={styles.shopping_center__buttons}>
                                    <a href={'https://goo.gl/maps/EM3vtH6SK9P4Winq6'}
                                       className={styles.shopping_center__button} target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            Посмотреть на карте
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.shopping_center__info}>
                                <p className={styles.shopping_center__name}>Agora</p>
                                <div className={styles.shopping_center__buttons}>
                                    <a href={'https://goo.gl/maps/m9VFekQ5YBkmDzAT8'}
                                       className={styles.shopping_center__button} target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            Посмотреть на карте
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                            <div className={styles.shopping_center__info}>
                                <p className={styles.shopping_center__name}>Mall of Antalya</p>
                                <div className={styles.shopping_center__buttons}>
                                    <a href={'https://goo.gl/maps/KLq6xqw8bb36xe8m7'}
                                       className={styles.shopping_center__button} target={'_blank'}>
                                        <AppButton mode={'white'}>
                                            Посмотреть на карте
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={connectionRef} className={'cart connection'}>
                    <div className={'home__container'}>
                        <div className={'connection__body'}>
                            <div className={'connection__header'}>
                                <AppTitle title={'Связь'}>
                                    <SvgSelector id={'plan-section-icon'}/>
                                </AppTitle>
                            </div>
                            <div className={'connection__main'}>
                                <div className={'connection-info'}>
                                    <p className={'connection-info__text'}>
                                        Ориентировочная стоимость сим-карт для турситов без ВНЖ у местных операторов
                                        TurkCell и TurkTelecom от 1000 до 1250 лир с включенным в него пакетом трафика.
                                    </p>
                                    {/*<div className={'connection-info__items'}>*/}
                                    {/*    <div className={'connection-item'}>*/}
                                    {/*        <div className={'connection-item__text'}>*/}
                                    {/*            Стоимость подключения*/}
                                    {/*        </div>*/}
                                    {/*        <div className={'connection-item__number'}>*/}
                                    {/*            385 TL*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className={'connection-item'}>*/}
                                    {/*        <div className={'connection-item__text'}>*/}
                                    {/*            Количество интернета*/}
                                    {/*        </div>*/}
                                    {/*        <div className={'connection-item__number'}>*/}
                                    {/*            15 GB*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className={'connection-info__button'}>*/}
                                    {/*    <a href={'https://www.google.com/maps/search/En+yak%C4%B1n+ptt/@36.8923966,30.6963169,16.45z?entry=ttu'}*/}
                                    {/*       className={'connection-info__button-link'} target={'_blank'}>*/}
                                    {/*        <AppButton mode={'white'}>*/}
                                    {/*            Найти ближайшие PTT*/}
                                    {/*        </AppButton>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className={'footer home__footer'}>
                <div className={'home__container'}>

                </div>
            </div>
            {popupIsOpen && <div className={'popup-image'} onClick={() => setPopupIsOpen(false)}>
                <AppPopup image={activeImgPopup} closePopup={() => setPopupIsOpen(false)}/>
            </div>}
            {menuIsOpen && <div className={'menu-wrap'}>
                <Menu closePopup={() => setMenuIsOpen(false)}
                      additionally={'hello'} handleClick={(value) => handleClick(value)}/>
            </div>}
            <div className={'img__test'}>
                <img src={mapMain} alt=""/>
            </div>
        </div>
    )
}
