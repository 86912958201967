import React, {FC} from 'react';
import SvgSelector from '../SvgSelector/SvgSelector';
import './Card.scss'
import {AppButton} from '../AppButton/AppButton';
import styles from '../../pages/home/Home.module.scss';


interface CardType {
    title: string
    iconMessenger: string
    icon: string
    text?: string
    plus?: string
    phone?: string
    messenger: string
    linkMessenger: string
    phone2?: string
    linkMessenger2?: string
    hospital?: boolean
    add?: boolean

}

export const Card: FC<CardType> = ({
                                       title,
                                       phone,
                                       phone2,
                                       linkMessenger2,
                                       icon,
                                       linkMessenger,
                                       messenger,
                                       text,
                                       iconMessenger,
                                       plus = '+',
                                       hospital,
                                       add,
                                   }) => {

    const phoneTrim = phone?.replace(/ /g, '')

    let phoneTrim2
    if (phone2){
        phoneTrim2 = phone2.replace(/ /g, '')

    }

    return (
        <div className={hospital ? 'card hospital' : 'card'}>
            <div className={'card__body'}>
                <div className={'card-header'}>
                    <h3 className={!phone2 ? 'card-header__title' : 'card-header__title active'}><div>{hospital && 'Отдел'}</div>{title}</h3>
                    <SvgSelector id={icon}/>
                </div>
                <div className={!phone2 ? 'card-main' : 'card-main2'}>
                    <a href={`tel: ${phone}`} className={styles.hospital__phone} target={'_blank'}>
                        <p className={'card-main__phone'}>{plus}{phone} {text}</p>
                    </a>
                </div>
                {add && <div className={'card-footer'}>
                    <div className={'card-footer__buttons'}>
                        {messenger && <div
                            className={iconMessenger == 'whatsapp-icon' ? 'card-footer__button top-margin' : 'card-footer__button'}>
                            <a href={linkMessenger || `https://wa.me/${phoneTrim}`} className={'card-footer__link'}
                               target={'_blank'}>
                                <AppButton mode={hospital ? 'red' : 'green'}>
                                    <SvgSelector id={iconMessenger}/>
                                    {messenger}
                                </AppButton>
                            </a>
                        </div>}
                        <div className={'card-footer__button'}>
                            <a href={`tel:${phone}`} className={'card-footer__link'} target={'_blank'}>
                                <AppButton mode={'blue'}>
                                    <SvgSelector id={'phone-icon'}/>
                                    Позвонить
                                </AppButton>
                            </a>
                        </div>
                    </div>
                </div>}
                {phone2 &&
                    <div>
                        <div className={'card-main card-main2'}>
                            <a href={`tel: ${phone2}`} className={styles.hospital__phone} target={'_blank'}>
                                <p className={'card-main__phone'}>{plus}{phone2} {text}</p>
                            </a>
                        </div>
                        <div className={'card-footer'}>
                            <div className={'card-footer__buttons'}>
                                {messenger && <div
                                    className={iconMessenger == 'whatsapp-icon' ? 'card-footer__button top-margin' : 'card-footer__button'}>
                                    <a href={linkMessenger2 || `https://wa.me/${phoneTrim2}`}
                                       className={'card-footer__link'}
                                       target={'_blank'}>
                                        <AppButton mode={hospital ? 'red' : 'green'}>
                                            <SvgSelector id={iconMessenger}/>
                                            {messenger}
                                        </AppButton>
                                    </a>
                                </div>}
                                <div className={'card-footer__button'}>
                                    <a href={`tel:${phone}`} className={'card-footer__link'} target={'_blank'}>
                                        <AppButton mode={'blue'}>
                                            <SvgSelector id={'phone-icon'}/>
                                            Позвонить
                                        </AppButton>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>
        </div>
    )
}