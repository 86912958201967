import React, {FC, useEffect, useRef, useState} from 'react';
import {AppButton} from '../AppButton/AppButton';
import SvgSelector from '../SvgSelector/SvgSelector';

import './AppPopup.scss'
import {useGesture} from '@use-gesture/react';


interface PopupType {
    closePopup: () => void
    image: string
    action?: () => void
    additionally?: string
}

const AppPopup: FC<PopupType> = ({image, closePopup, action, additionally}) => {
    console.log('render - AppPopup')
    const imageRef = useRef<any | null>(null);
    const imageContainerRef = useRef<any | null>(null);

    const [crop, setCrop] = useState({x: 0, y: 0, scale: 1});


    useEffect(()=> {

    }, [crop])




    useGesture({
        onDrag: ({offset: [dx, dy]}) => {
            setCrop((crop) => ({...crop, x: dx, y: dy}))
        },
        onPinch: ({offset: [d]}) => {
            setCrop((crop) => ({...crop, scale: d}))
        },
        //
        // onDragEnd: () => {
        //     let newCrop = crop
        //     let imageBounds = imageRef.current.getBoundingClientRect();
        //     let containerBounds = imageContainerRef.current.getBoundingClientRect();
        //     let orientalWidth = imageRef.current.clientWidth
        //     let widthOverhang = (imageBounds.width - orientalWidth) / 2
        //
        //     if (imageBounds.left > containerBounds.left) {
        //         newCrop.x = 0;
        //     } else if (imageBounds.right < containerBounds.right) {
        //         newCrop.x = -(imageBounds.width - containerBounds.width) + widthOverhang
        //     }
        //
        //     if (imageBounds.top > containerBounds.top) {
        //         newCrop.y = 0;
        //     } else if (imageBounds.bottom < containerBounds.bottom) {
        //         newCrop.y = -(imageBounds.height - containerBounds.height)
        //     }
        //     // setCrop((crop) => ({...crop, x: 0, y: 0}))
        //     setCrop(newCrop)
        //     console.log(newCrop)
        //     console.log('end')
        // }
    }, {
        drag: {
            // initial: () => [crop.x, crop.y]
        },
        pinch: {
            // @ts-ignore
            // distanceBounds: {min: 0}
        },
        target: imageRef,
        eventOptions: {passive: false}
    })


    return (
        <div onClick={closePopup} className={'popup'}>
            <div onClick={e => e.stopPropagation()} className={'popup__wrap'}>
                <div onClick={closePopup} className={'popup__closure'}>
                    <SvgSelector id={'close-icon'} svgColor={'#000'}/>
                </div>
                <div className={'popup__header'}>
                </div>
                <div ref={imageContainerRef} className={'popup__main'}>
                    <img draggable="false" ref={imageRef} src={image} alt=""
                         style={{top: crop.y, left: crop.x, transform: `scale(${crop.scale})`, touchAction: 'none'}}
                    />
                </div>
                <div className={'popup__footer'}>

                </div>
            </div>
        </div>
    );
};

export default AppPopup;
