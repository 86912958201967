import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../assets/img/icons/logo.svg'

import './logo.scss'
import {useAppDispatch} from '../../store/store';

export const Logo = React.memo(() => {
   // console.log('rerender Logo')
   const dispatch = useAppDispatch()

   return (
      <Link className={'link'} to={'/'}>
         <div className={'logo'}>
            <img src={logo} alt='Antalya 2023' />
            <div>
               <h2>Antalya 2024</h2>
            </div>
         </div>
      </Link>
   )
})