import React, {FC, useRef} from 'react';
import {AppButton} from '../AppButton/AppButton';
import SvgSelector from '../SvgSelector/SvgSelector';


import styles from './Menu.module.scss'


interface PopupType {
    closePopup: () => void
    action?: () => void
    additionally?: string
    handleClick: (value: string) => void
}

const Menu: FC<PopupType> = ({closePopup, action, additionally, handleClick}) => {

    return (
        <div onClick={closePopup} className={styles.menu_popup}>
            <div onClick={e => e.stopPropagation()} className={styles.menu_popup__wrap}>
                <div onClick={closePopup} className={styles.menu_popup__closure}>
                    <SvgSelector id={'close'} svgColor={'#000'}/>
                </div>
                <div className={styles.menu_popup__header}>
                    <h2 className={styles.menu_popup__title} onClick={() => handleClick('homeRef')}>Карта сайта</h2>
                </div>
                <div className={styles.menu_popup__main}>
                    <h3 className={styles.menu_popup__subtitle} onClick={() => handleClick('contactsRef')}>Контактная
                        информация</h3>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('hospitalRef')}>Отдел первой помощи</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('placementDepartmentRef')}>Отдел размещения</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('informationBureauRef')}>Бюро
                        информации</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('touristInformationRef')}>Справочная
                        для туристов</h4>
                    <div className={styles.menu_popup__line}></div>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('locationsInformationRef')}>
                        Информация о расположении комплекса</h4>
                    <h3 className={styles.menu_popup__subtitle}
                        onClick={() => handleClick('transportInformationRef')}>Информация о транспорте</h3>
                    <h4 className={styles.menu_popup__point}
                        onClick={() => handleClick('transportCardRef')}>Транспортная карта</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('appRef')}>Приложение Antalya
                        Kart</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('googleMapRef')}>Гугл карты</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('airportAntalyaRef')}>Аэропорт Анталии</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('taxiRef')}>Такси</h4>
                    <div className={styles.menu_popup__line}></div>
                    <h3 className={styles.menu_popup__subtitle}
                        onClick={() => handleClick('usefulLocationsRef')}>Полезные локации</h3>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('pharmacyRef')}>Аптеки</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('hospitalRef')}>Больницы</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('shoppingCenterRef')}>Торговые центры</h4>
                    <h4 className={styles.menu_popup__point} onClick={() => handleClick('connectionRef')}>Связь</h4>
                    <div className={styles.menu_popup__line}></div>
                </div>
                <div className={styles.menu_popup__footer}>
                    <div className={styles.menu_popup__button_cancellation}>

                    </div>
                    <div className={styles.menu_popup__button_delete}>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;

